import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://5bd8da6f48b42cec8d6f464ac3be1ebd@o4506702980579328.ingest.sentry.io/4506702982479872',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0

	// If you don't want to use Session Replay, just remove the line below:
	// integrations: [
	// 	replayIntegration(),
	// 	Sentry.feedbackIntegration({
	// 		// Additional SDK configuration goes in here, for example:
	// 		colorScheme: 'system'
	// 	})
	// ]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
